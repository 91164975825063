export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js")
];

export const server_loads = [2,3,5,6];

export const dictionary = {
		"/": [~7],
		"/about": [8],
		"/blog": [9,[2]],
		"/blog/[slug]": [~10,[2,3]],
		"/blog/[slug]/[do]": [~11,[2,3,4]],
		"/discipleedit": [14],
		"/discipleedit/[edit]": [~15],
		"/disciple": [12],
		"/disciple/[details]": [~13],
		"/namakarana": [16,[5]],
		"/namakarana/createtemple": [~18,[5]],
		"/namakarana/[do]": [~17,[5]],
		"/namavali": [~19],
		"/temple": [20,[6]],
		"/temple/createtemple": [~22,[6]],
		"/temple/showtemple": [~23,[6]],
		"/temple/[do]": [~21,[6]],
		"/xlupload": [24]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.js";